var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.id],
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.allowSelect
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.disableOk,
                              size: "sm",
                              variant: "success",
                            },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$i18n.t(
                            "MANAGE" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _c("ListFilter", {
            attrs: { termOnly: true, term: _vm.searchFilterTerm },
            on: { applyFilter: _vm.applyFilter },
          }),
          _vm.allowManage
            ? _c("div", { staticClass: "grid-toolbar border" }, [
                _vm.canAdd()
                  ? _c(
                      "span",
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_ADD_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("button.add"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: { id: "BTN_ADD_" + _vm.id },
                            on: {
                              click: function ($event) {
                                return _vm.modalOpen(true)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              style: { color: "var(--grid-toolbar-button)" },
                              attrs: { icon: ["far", "plus"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canView()
                  ? _c(
                      "span",
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_EDIT_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("button.edit"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.disableEdit,
                              id: "BTN_EDIT_" + _vm.id,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.modalOpen(false)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "pen-to-square"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canAdd()
                  ? _c(
                      "span",
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_DUPLICATE_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("button.duplicate"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.disableDuplicate,
                              id: "BTN_DUPLICATE_" + _vm.id,
                            },
                            on: { click: _vm.showDuplicateDialog },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clone"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canDelete()
                  ? _c(
                      "span",
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_DELETE_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("button.delete"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.disableDelete,
                              id: "BTN_DELETE_" + _vm.id,
                            },
                            on: { click: _vm.rowDelete },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "trash-can"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canAdd()
                  ? _c(
                      "span",
                      [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_IMPORT_DOCUMENT_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("company.button.import_document"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: { id: "BTN_IMPORT_DOCUMENT_" + _vm.id },
                            on: { click: _vm.fileImport },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "inbox-in"] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "span",
                  [
                    _c("b-popover", {
                      attrs: {
                        target: "BTN_EXPORT_DOCUMENT_" + _vm.id,
                        placement: "top",
                        triggers: "hover",
                        content: _vm.$t("company.button.export_document"),
                      },
                    }),
                    _c(
                      "b-btn",
                      {
                        attrs: { id: "BTN_EXPORT_DOCUMENT_" + _vm.id },
                        on: { click: _vm.fileExport },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "inbox-out"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                      _vm.colorMouseEnterEvent,
                      _vm.onColoringOver,
                    ]),
                  },
                  [
                    _c(
                      "b-dropdown",
                      {
                        ref: "coloring",
                        staticClass: "action-bar-dropdown",
                        attrs: {
                          id: "BTN_COLORING_" + _vm.id,
                          "toggle-class": "text-decoration-none",
                          "no-caret": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "palette"] },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1244906976
                        ),
                      },
                      [
                        _c(
                          "b-dropdown-group",
                          { attrs: { header: _vm.$t("colorby") } },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange("none")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [_vm._v(_vm._s(_vm.$t("none")))]
                                ),
                                _vm.coloring.none
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange("company")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("company.coloring.company"))
                                    ),
                                  ]
                                ),
                                _vm.coloring.company
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onColorChange("location")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("company.coloring.location")
                                      )
                                    ),
                                  ]
                                ),
                                _vm.coloring.location
                                  ? _c("font-awesome-icon", {
                                      staticClass: "active-check",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-balham company-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "company-grid",
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              defaultColDef: _vm.defaultColDef,
              getRowId: function (params) {
                return params.data.uuId
              },
              rowData: _vm.rowData,
              groupDefaultExpanded: "-1",
              rowSelection:
                _vm.preselected || _vm.forceSingleSelection
                  ? "single"
                  : "multiple",
              suppressCellFocus: "",
              treeData: "",
              suppressContextMenu: "",
              suppressMultiSort: "",
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
      _vm.allowManage && _vm.companyShow
        ? _c("CompanyModal", {
            attrs: {
              id: _vm.companyId,
              masterCompany: _vm.masterCompany,
              parentData: _vm.selectedParent,
              show: _vm.companyShow,
              title: _vm.companyTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.companyShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.duplicateTitle,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.duplicateCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.duplicateOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.duplicate")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.duplicateShow,
            callback: function ($$v) {
              _vm.duplicateShow = $$v
            },
            expression: "duplicateShow",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("company.field.name"),
                "label-for": "name",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": _vm.$t("company.field.name"),
                      "data-vv-name": "duplicate.name",
                      "data-vv-delay": "500",
                      trim: "",
                    },
                    model: {
                      value: _vm.duplicateName,
                      callback: function ($$v) {
                        _vm.duplicateName = $$v
                      },
                      expression: "duplicateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showDuplicateNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "  " + _vm._s(_vm.errors.first("duplicate.name")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("company.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "" +
                      (_vm.selected.length > 1
                        ? "company.confirmation.delete_plural"
                        : "company.confirmation.delete"),
                    [
                      "" +
                        (_vm.hasChildNodes
                          ? _vm.$t("company.confirmation.child_nodes")
                          : ""),
                    ]
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("GanttImportDialog", {
        attrs: {
          properties: [
            { value: "color", text: _vm.$t("field.color") },
            { value: "identifier", text: _vm.$t("field.identifier") },
            { value: "location", text: _vm.$t("company.field.location") },
            { value: "name", text: _vm.$t("company.field.name") },
            { value: "task_path", text: _vm.$t("document.path") },
            { value: "tag", text: _vm.$t("field.tag") },
            { value: "type", text: _vm.$t("company.field.type") },
          ],
          mode: "COMPANY",
          show: _vm.docImportShow,
          title: _vm.$t("company.button.import_document"),
        },
        on: {
          "modal-ok": _vm.docImportOk,
          "modal-cancel": _vm.docImportCancel,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }