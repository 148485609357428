var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-filter-search-container" },
    [
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            staticClass: "search-field",
            attrs: { placeholder: _vm.$t("button.search"), trim: "" },
            nativeOn: {
              keydown: function ($event) {
                return _vm.keydownHandler.apply(null, arguments)
              },
            },
            model: {
              value: _vm.searchFilter,
              callback: function ($$v) {
                _vm.searchFilter = $$v
              },
              expression: "searchFilter",
            },
          }),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-btn",
                { attrs: { variant: "danger" }, on: { click: _vm.clear } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "search-clear",
                    attrs: { icon: ["far", "xmark"] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-btn",
                {
                  staticClass: "search-append-bg",
                  attrs: { variant: "secondary" },
                  on: { click: _vm.search },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "magnifying-glass"] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }