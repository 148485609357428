
<template>
  <div class="list-filter-search-container">
    <b-input-group>
      <b-form-input class="search-field" v-model="searchFilter" :placeholder="$t('button.search')" trim
        @keydown.native="keydownHandler"></b-form-input>
      <b-input-group-append>
        <b-btn @click="clear" variant="danger"><font-awesome-icon class="search-clear" :icon="['far', 'xmark']"/></b-btn>
      </b-input-group-append>
      <b-input-group-append>
        <b-btn  @click="search" class="search-append-bg" variant="secondary"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
      </b-input-group-append>
    </b-input-group>
    
  </div>
</template>

<script>
import { escapeRegExp } from '@/helpers'

export default {
  name: 'ListFilter',

  props: {
    entry:         { type: Array },
    calendarName:  { type: String },
    termOnly:      { type: Boolean, default: false},
    term:          { type: String, default: null}
  },
  data() {
    return {
      searchFilter: ""
    };
  },
  created() {
    if (this.term) {
      this.searchFilter = this.term;
    }
  },
  watch: {
    term(newValue) {
      this.searchFilter = newValue;
    }
  },
  methods: {
    search() {
      if (this.termOnly) {
       this.$emit('applyFilter', this.searchFilter);
      } else {
        this.$emit('applyFilter', "(?is).*" + escapeRegExp(this.searchFilter) + ".*");
      }
    },
    clear() {
      this.searchFilter = "";
      this.search();
    },
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.search();
      }
    }
  }
}
</script>
<style lang="scss">
.list-filter-search-container {
  display: flex;
  margin-left: auto;
}

.search-field {
  flex-grow: 1;
  display: inline-block;
}

.search-clear {
  color: var(--white);
}

.btn.btn-secondary.search-append-bg {
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--form-control-addon-bg) !important;
}
</style>
