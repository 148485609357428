<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter :termOnly="true" :term="searchFilterTerm" @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <span v-if="canAdd()">
          <b-popover
            :target="`BTN_ADD_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.add')">
          </b-popover>
          <b-btn :id="`BTN_ADD_${id}`" @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>  
        </span>
        <span v-if="canView()">
          <b-popover
            :target="`BTN_EDIT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.edit')">
          </b-popover>
          <b-btn :disabled="disableEdit" :id="`BTN_EDIT_${id}`" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>  
        </span>
        <span v-if="canAdd()">
          <b-popover
            :target="`BTN_DUPLICATE_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.duplicate')">
          </b-popover>
          <b-btn :disabled="disableDuplicate" :id="`BTN_DUPLICATE_${id}`" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>
        </span>
        <span  v-if="canDelete()">
          <b-popover
            :target="`BTN_DELETE_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.delete')">
          </b-popover>
          <b-btn :disabled="disableDelete" :id="`BTN_DELETE_${id}`" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>  
        </span>
        <span v-if="canAdd()">
          <b-popover
            :target="`BTN_IMPORT_DOCUMENT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('company.button.import_document')">
          </b-popover>
          <b-btn :id="`BTN_IMPORT_DOCUMENT_${id}`" @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
        </span>
        <span>
          <b-popover
            :target="`BTN_EXPORT_DOCUMENT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('company.button.export_document')">
          </b-popover>
          <b-btn :id="`BTN_EXPORT_DOCUMENT_${id}`" @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
        </span>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('company')" href="#">
                <span class="action-item-label">{{ $t('company.coloring.company') }}</span><font-awesome-icon class="active-check" v-if="coloring.company" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('location')" href="#">
                <span class="action-item-label">{{ $t('company.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="coloring.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham company-grid-height" id="company-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :autoGroupColumnDef="autoGroupColumnDef"
            :columnDefs="columnDefs"
            :context="context"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            :rowData="rowData"
            groupDefaultExpanded="-1"
            :rowSelection="preselected || forceSingleSelection? 'single': 'multiple'"
            suppressCellFocus
            treeData
            suppressContextMenu
            suppressMultiSort
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <CompanyModal v-if="allowManage && companyShow" :id="companyId" :masterCompany="masterCompany" :parentData="selectedParent" :show.sync="companyShow" @success="modalSuccess" :title="companyTitle"/>

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('company.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('company.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('company.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(`${selected.length > 1? 'company.confirmation.delete_plural':'company.confirmation.delete'}`, [`${ hasChildNodes ? $t('company.confirmation.child_nodes'):'' }`]) }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'location', text: $t('company.field.location') }, { value: 'name', text: $t('company.field.name') }, { value: 'task_path', text: $t('document.path') }, { value: 'tag', text: $t('field.tag') }, { value: 'type', text: $t('company.field.type') }]" :mode="'COMPANY'" :show="docImportShow"
      :title="$t('company.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common';
import { getPermissionDenyProperties } from '@/helpers/permission';
import ListFilter from '@/components/ListFilter/ListFilter';
import { strRandom, addTags, invertColor } from '@/helpers';
import { companyService, viewProfileService, companyLinkLocationService, companyLinkTagService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { debounce } from 'lodash';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

export function CompanyBeforeMount(self, data /** , title*/) {
  const profileKey = 'company_selector_list';
  const getColumnDefs = (c) => {
    return {
      colId: c.colId
      , width: c.actualWidth
      , sort: c.sort != null? c.sort : null
      , sortIndex: c.sortIndex != null? c.sortIndex : null
    }
  }

  data.gridOptions = {
    suppressRowClickSelection: true,

    onSelectionChanged: function (event) {
      data.selected.splice(0, data.selected.length, ...(event.api.getSelectedNodes().map(i => i.data)));
      if (event.api.getSelectedNodes().length !== 0) {
        data.selectedParent = event.api.getSelectedNodes()[0].parent.data;
      }
      else {
        data.selectedParent = null;
      }

      const master = data.selected.filter(c => c.type === 'Primary');
      data.disableEdit = data.selected.length != 1;
        data.disableDuplicate = data.selected.length < 1 || master.length > 0;
      if (typeof self.checkDisableButtons !== "undefined") {
        self.checkDisableButtons(data);
      }
      else {
        data.disableOk = data.disableDelete = data.selected.length < 1;
        data.disableDelete = data.selected.length < 1 || master.length > 0 || (data.selected.length > 0 && data.selected.filter(f => f.uuId === self.$store.state.company.uuId).length !== 0);
      }
    },
    onColumnVisible: function(params) {
      let fromToolPanel = params.source == "toolPanelUi"
      if (fromToolPanel) {
        let colKey = params.column.colId;
        let columnMenuColumnIndex = params.columnApi
          .getAllGridColumns()
          .findIndex(col => {
            return col.colId === self.lastOpenColumnMenuParams.column.colId;
          });
        params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
      }
      const cols = params.columnApi.getAllGridColumns().map(i => { 
        return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
      const columnState =  params.columnApi.getColumnState();
      //get the actual hide value from columnState
      for (const col of columnState) {
        const found = cols.find(i => i.colId == col.colId)
        if (found) {
          found.hide = col.hide;
        }
      }
      cols.sort(columnDefSortFunc)
      for (const [index,c] of cols.entries()) {
        params.columnApi.moveColumn(c.colId, index);
      }

      const columns = params.columnApi.getAllDisplayedColumns();
      self.settings[profileKey] = columns.map(c => getColumnDefs(c));
      self.updateViewProfile();
    },
    postProcessPopup: params => {
      if ((params.type == 'columnMenu')) {
        self.lastOpenColumnMenuParams = params;
      }
    },
    onSortChanged: function(event) {
      const columns = event.columnApi.getAllDisplayedColumns();
      self.settings[profileKey] = columns.map(c => getColumnDefs(c));
      self.updateViewProfile();
    },
    onDragStopped: function(event) {
      const columns = event.columnApi.getAllDisplayedColumns();
      self.settings[profileKey] = columns.map(c => getColumnDefs(c));
      self.updateViewProfile();
    },
    onFirstDataRendered: function(event) {
      if (self.newToProfile != null && self.newToProfile == true) {
        self.newToProfile = null;
        self.gridApi.sizeColumnsToFit();
        self.$nextTick(() => {
          const columns = event.columnApi.getAllDisplayedColumns();
          self.settings[profileKey] = columns.map(c => getColumnDefs(c));
          self.updateViewProfile();
        })
      }
      setTimeout(() => {
        self.processNodes();
      }, 100);
    },
    getDataPath: function(data) {
      if (typeof data !== 'undefined') {
        const path = data.path.split(', ');
        return path;
      }
      return [];
    },
  };
  const colDefs = [
    {
      headerName: self.$t('company.field.headCount'),
      field: 'staffCount'
      , hide: false
      , minWidth: 100
      , sortable: false
      , editable: false
    }, 
    {
      headerName: self.$t('company.field.genericHeadCount'),
      field: 'genericStaffCount'
      , hide: false
      , minWidth: 100
      , sortable: false
      , editable: false
    },
    {
      headerName: self.$t('company.field.type'),
      field: 'type',
      hide: false,
      minWidth: 100
    },
    { //Not visible in grid but is needed when exporting data
      headerName: self.$t('company.field.location'),
      field: 'location',
      minWidth: 100,
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true
    },
    {
      headerName: self.$t('field.tag'),
      field: 'tag',
      minWidth: 100,
      hide: true
    },
    {
      headerName: self.$t('field.color'),
      field: 'color',
      cellRenderer: 'colorCellRenderer',
      minWidth: 100,
      hide: true
    },
    {
      headerName: self.$t('field.identifier_full'),
      field: 'identifier',
      minWidth: 100,
      hide: true
    },
    {
      field: 'path',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true
    }
  ];
  const linkedEntities = [
    { selector: 'COMPANY.TAG', field: 'tag', properties: ['name'] }
    , { selector: 'COMPANY.LOCATION', field: 'location', properties: ['name'] }
  ]
  self.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
    .finally(() => {
    filterOutViewDenyProperties(colDefs, 'COMPANY', linkedEntities);
    colDefs.sort(columnDefSortFunc);
    data.columnDefs = colDefs;
  });
  
  data.defaultColDef = {
    sortable: false,
    resizable: true,
    minWidth: 400,
    hide: true,
    lockPinned: true,
    menuTabs: ['columnsMenuTab']
  };
  data.autoGroupColumnDef = {
    headerName: self.$t('company.field.name'),
    field: 'uuId',
    pinned: 'left',
    cellRendererParams: {
      checkbox: true,
      suppressCount: true,
      label: 'name',
      decorateCompany: false,
      ignoreAvatar: true,
      innerRenderer: 'detailLinkCellRenderer'
    },
    cellStyle: params => {
        if (params.data &&
            params.data.color &&
            self.coloring.company) {
            return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
        }
        else if (params.data &&
            params.data.locationColor &&
            self.coloring.location) {
            return { background: params.node.data.locationColor, color: invertColor(params.node.data.locationColor, true) };
        }
    }
  };

  data.context = {
    componentParent: self
  };
}

async function extractRowsFromData(self, path, data, parentData) {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const deptPath = path === '' ? data[i].uuId : `${path}, ${data[i].uuId}`;
    data[i].path = deptPath;
    
    // add tags
    if (data[i].uuId in self.companyMap) {
      data[i].tag = self.companyMap[data[i].uuId].tag;
      data[i].staffCount = self.companyMap[data[i].uuId].staffCount;
      data[i].genericStaffCount = self.companyMap[data[i].uuId].genericStaffCount;
      if (Array.isArray(self.companyMap[data[i].uuId].locationColor) && self.companyMap[data[i].uuId].locationColor.length > 1) {
        data[i].locationColor = self.companyMap[data[i].uuId].locationColor[0];
      }
      
    }
      
    // don't add duplicates
    const index = rows.findIndex((r) => { return r.uuId === data[i].uuId });
    if (index === -1 && self.exclude !== data[i].uuId) {
      rows.push(data[i]);
      
      if (parentData) {
        self.parentMap[data[i].uuId] = parentData;
      }
      
      const companyData = data[i];
    
      if(companyData) {
        if (typeof companyData.companyList !== 'undefined') {
          const childData = await extractRowsFromData(self, deptPath, companyData.companyList, data[i]);
             
          rows.push(...childData);
        }
      }
    }
  }
  return rows;
}

export function pruneTree(self, data, searchFilter) {
  if (searchFilter === '') {
    return data;
  }
  var regex = new RegExp(searchFilter, "i");
  for (var i = data.length - 1; i >= 0; i--) {

    // add tags
    if (data[i].uuId in self.companyMap) {
      data[i].tag = self.companyMap[data[i].uuId].tag;
      data[i].locationColor = self.companyMap[data[i].uuId].locationColor[0];
    }
     
    if (typeof data[i].companyList !== 'undefined') {
      data[i].companyList = pruneTree(self, data[i].companyList, searchFilter);
    }
    
    var found = false;
    if (typeof data[i].name !== 'undefined' && data[i].name.match(regex) != null) {
      found = true;
    } else if (typeof data[i].identifier !== 'undefined' && (data[i].identifier.match(regex) != null)) {
      found = true;
    } else if (typeof data[i].tag !== 'undefined' && (data[i].tag.filter(t => t.match(regex) != null).length !== 0)) {
      found = true;
    }
    if ((typeof data[i].companyList === 'undefined' || data[i].companyList.length === 0) && !found) {
      data.splice(i, 1);
    }
  }
  return data;
}

export default {
  name: "CompanySelectorModal",
  components: {
    "ag-grid-vue": AgGridVue,
    CompanyModal: () => import('./CompanyModal.vue'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),

    //aggrid cell renderer/editor/header/Overlay component
    /* eslint-disable vue/no-unused-components */
    //Renderer
    'detailLinkCellRenderer': DetailLinkCellRenderer, // eslint-disable-line vue/no-unused-components
    'colorCellRenderer': ColorCellRenderer, // eslint-disable-line vue/no-unused-components
    //Overlay
    noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    title: { 
      type: String,
      default: null 
    },
    show: {
      type: Boolean,
      required: true
    },
    exclude: {
      type: String,
      default: null
    },
    preselected: {
      type: String,
      default: null
    },
    forceSingleSelection: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    }
  },
  data: function() {
    return {
      id: `COMPANY_LIST_${strRandom(5)}`,
      permissionName: "COMPANY",
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: [],
      context: null,
      defaultColDef: null,
      rowData: null,
      treeData: null,
      getDataPath: null,
      autoGroupColumnDef: null,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,
      selected: [],
      restoreSelection: null,
      selectedParent: null,
      hasChildNodes: false,

      companyId: null,
      companyShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,
      parentMap: {},

      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: "",
      searchFilterTerm: "",
      listStaff: false,

      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
      
      docImportShow: false,
      companyMap: {},
      
      coloring: {
        none: true,
        company: false,
        location: false
      },
      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    CompanyBeforeMount(this, this, this.$t('company.title'));
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.mandatoryFields = ['name', 'type']
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
    this.mandatoryFields = null
  },
  watch: {
    show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        this.selected = [{ uuId: this.preselected }];
        this.loadViewProfile();
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    masterCompany() {
      if (this.treeData === null) {
        return null;
      }
      
      const master = this.treeData.filter(c => c.type === 'Primary');
      return master.length > 0 ? master[0] : null;
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    companyTitle() {
      return this.companyId && this.companyId.indexOf('COMPANY_NEW') == -1? this.$t('company.title_detail'): this.$t('company.title_new');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title: this.$t('company.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('company.title_duplicate');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    processNodes() {
      const self = this;
      if (this.gridApi) {
        this.gridApi.forEachNode((node/**, b */) => {
          if (node.id === self.restoreSelection) {
            node.setSelected(true);
          }
        });
      }
    },
    async onGridReady(/**params */) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.reloadData();
      this.restoreSelection = this.preselected;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.companyId = `COMPANY_NEW_${strRandom(5)}`;
        
        // for a new company the selected node will be the parent of the new company
        if (this.gridApi.getSelectedNodes().length !== 0) {
          this.selectedParent = this.gridApi.getSelectedNodes()[0].data;
        }
        else {
          this.selectedParent = this.masterCompany;
        }
      } else {
        this.companyId = this.selected[0].uuId;
        // for an edited company set the parent node data
        if (this.companyId in this.parentMap) {
          this.selectedParent = this.parentMap[this.companyId];
        }
        else {
          this.selectedParent = null;
        }
      }
      this.companyShow = true;
      this.resetAlert();
    },
    async reloadData() {
      if (this.lackOfMandatoryField()) {
        this.noRowsMessage = this.$t('entity_selector.error.insufficient_permission_to_show_data')
        if (this.gridOptions.api != null) {
          this.gridOptions.api.hideOverlay()
          setTimeout(() => {
            this.gridOptions.api.showNoRowsOverlay()
          })
        }
        return
      } else {
        this.noRowsMessage = null
      }

      this.restoreSelection = this.selected.length !== 0 ? this.selected[0].uuId : null;
      if (this.rowData) {
        this.rowData.splice(0, this.rowData.length);
      }
      
      const companyList = await companyService.list({start: 0, limit: -1})
      .then(response => {
        return response.data;
      })
      .catch((e) => {
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null) {
          if (e.response.data.jobClue.clue == 'Forbidden_entity') {
            this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'))
            return null
          } else {
            this.httpAjaxError(e);
          }
        }
        this.showNoRowsOverlay(null)
        return null;
      });

      if (companyList == null) {
        this.rowData = [];
        return;
      }

      this.companyMap = companyList.reduce((map, c) => { 
      
        map[c.uuId] = c;
        return map;
      }, []);
      
      const companyData = await companyService.tree(false).then((response) => {
        return response.data[response.data.jobCase];
      })
      .catch(e => {
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null) {
          if (e.response.data.jobClue.clue == 'Forbidden_entity') {
            this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'))
            return null
          }
        }
        this.showNoRowsOverlay(null)
      });

      if (companyData == null) {
        this.rowData = [];
        return;
      }
    
      this.treeData = pruneTree(this, companyData, this.searchFilter);
      this.rowData = await extractRowsFromData(this, '', this.treeData, null);
      setTimeout(() => {
        this.processNodes();
      }, 100);
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    modalSuccess(payload) {
      this.reloadData();
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('company.progress.deleting');
      const toDeleteIds = this.selected.map(i => { return { uuId: i.uuId } });
      const toDeleteIdNames = this.selected.map(i => { return { uuId: i.uuId, name: i.name } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`company.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];
      
      const success = await companyService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('company.delete_partial');
          alertMsgDetailTitle = this.$t(`company.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
        return true;
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`company.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response && e.response.status == 422) {
          alertMsgDetailTitle = this.$t(`company.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = e.response.data[e.response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
        return false;
      });
      
      if (success) {
        const index = toDeleteIds.findIndex((i) => { return i.uuId === this.$store.state.company.uuId });
        if (index !== -1) {
          // change the selected company to the master
        this.$set(this.$store.state, 'company', self.masterCompany);
        }
        
        await this.reloadData();
        this.selected = [];
        this.selectedParent = null;
        this.gridApi.deselectAll();
      }
      
      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name, avatarRef: i.data.avatarRef, bannerRef: i.data.bannerRef, type: i.data.type } });
     
      if(this.preselected && details.length > 0) {
        details[0].oldId = this.preselected;
        details.splice(1, details.length -1);
      }
      const ids = details.map(i => i.uuId);
      this.$emit('ok', { ids, details });
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    openDetail(id) {
      this.companyId = id;
      if (this.companyId in this.parentMap) {
        this.selectedParent = this.parentMap[this.companyId];
      }
      else {
        this.selectedParent = null;
      }
      this.companyShow = true;
      this.resetAlert();
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      
      this.searchFilterTerm = this.settings[`company_selector_search`] = this.searchFilter;
      this.updateViewProfile()
      
      this.reloadData();
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.selected[0].name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      const self = this;
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('company.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          const selectedNode = self.gridApi.getRowNode(self.selected[0].uuId);
          const data = { name: this.duplicateName };
          if (selectedNode.parent.data) {
            data.parent = selectedNode.parent.data.uuId;
          }
          
          companyService.clone(this.selected[0].uuId, data)
          .then(() => {
            this.resetAlert({ msg: this.$t('company.duplicate') });
            this.reloadData();
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.COMPANY')]);
            if(e.response && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              if ('Not_unique_key' === clue) { 
                alertMsg = this.$t('company.error.duplicate_not_master');
              } else if ('Unknown_holder' === clue) {
                alertMsg = this.$t('company.error.duplicate_not_found');
              }
            }
            this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
            this.scrollToTop();
          })
          .finally(() => {
            this.duplicateShow = false;
            this.errors.clear();
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadViewProfile() {
      const self = this;
      this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.company_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.company_selector_list);
            self.coloring.none = self.settings.company_selector_coloring ? self.settings.company_selector_coloring.none : false;
            self.coloring.company = self.settings.company_selector_coloring ? self.settings.company_selector_coloring.company : false;
            self.coloring.location = self.settings.company_selector_coloring ? self.settings.company_selector_coloring.location : false;
            
            this.searchFilterTerm = typeof this.settings[`company_selector_search`] !== 'undefined' ? this.settings[`company_selector_search`] : '';
            if (this.searchFilterTerm !== '') {
              this.applyFilter(this.searchFilterTerm);
            }
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    processCellCallback(self) {
      return function(params) {
        if (params.column.colId.indexOf('ag-Grid-AutoColumn') !== -1) {
          return params.node.data.name;
        }
        else if (params.column.colId.indexOf('path') !== -1) {
          const ids = params.node.data.path.split(', ');
          let path = '';
          for (const id of ids) {
            const parent = self.parentMap[id];
            if (parent) {
              path = path !== '' ? `${path}/${parent.name}` : parent.name;
            }
          }
          
          if (path !== '') {
            return `${path}/${params.node.data.name}`;
          }
          else {
            return params.node.data.name;
          }
        }
        else if (params.column.colId === 'location') {
          return self.companyMap[params.node.data.uuId].location;
        }
        return params.value;
      }
    },
    async fileExport() {
     
      const companyList = await companyService.list({start: 0, limit: -1})
      .then(response => {
        return response.data;
      })
      .catch((e) => {
        this.httpAjaxError(e);
        return null;
      });
      
      if (companyList) {
        for (const company of companyList) {
          this.companyMap[company.uuId] = company;
        }
      }
      
      const self = this;
      this.gridApi.exportDataAsExcel({ 
        fileName: 'Companies'
        , sheetName: 'Companies'
        , allColumns: true
        , rowHeight: 20
        , processCellCallback: self.processCellCallback(self)
      });
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.resetAlert();
      await this.addCompanies(items[0].type === 'Primary' ? items[0].items : items, this.masterCompany.uuId);
      this.inProgressShow = false;
      this.reloadData();
    },
    async addCompanies(items, parent) {
      this.inProgressLabel = this.$t('company.progress.importing', [0]);
      let percentage = 0;
      for (const item of items) {
        const data = {
          name: item.name,
          type: item.type ? item.type : 'Office',
          parent: parent,
          identifier: item.identifier,
          color: item.color
        }
        
        const result = await companyService.create([data])
        .then(response => {
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            return feedbackList[0].uuId;
          }
        })
        .catch(() => {
          return null;
        });
        
        if (result &&
            item.location &&
            item.location.uuId) {
          await companyLinkLocationService.create(result, [item.location])    
        }
        
        if (result &&
          item.tag) {
          await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), companyLinkTagService);
        }
        
        if (item.items &&
            result) {
          await this.addCompanies(item.items, result);   
        }
        percentage++;
        this.inProgressLabel = this.$t('company.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['company_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.company) {
        return data.color;
      }
      else if (data &&
        data.locationColor &&
        this.coloring.location) {
        return data.locationColor;
      }
    },
    lackOfMandatoryField() {
      //Permission check on mandatory properties
      const viewDenyProperties = getPermissionDenyProperties('COMPANY', 'VIEW')
      let showNoData = false
      for (const field of this.mandatoryFields) {
        if (viewDenyProperties.includes(field)) {
          showNoData = true
        }
      }
      return showNoData
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('company.grid.no_data');
    },
    showNoRowsOverlay(msg=null) {
      this.noRowsMessage = msg
      if (this.gridOptions != null && this.gridOptions.api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          this.gridOptions.api.showNoRowsOverlay()
        })
      }
    }
  }
}


</script>

<style lang="scss" scoped>
  .grid-toolbar {
    .btn.btn-secondary {
      background-color: transparent;
      border-color: transparent;
      padding: 2px 6px;
      margin: 8px 3px;
      border-radius: 3.2px;
      color: var(--grid-toolbar-button);

      &:focus {
        box-shadow: none;
      }
      &:first-child {
        margin-left: 8px;
      }
    }
  }

  .company-grid-height {
    height: calc(100vh - 380px);
    min-height: 250px;
  }
  
  .active-check {
    position: absolute;
    right: 10px;
  }

</style>